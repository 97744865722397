import React from 'react';
import c from './back-button.module.scss';
import { Button, Icon, Link } from '@monash/portal-react';
import { useGuideParams } from '../../../../../utils/hooks/useGuideParams';

const BackButton = () => {
  const guideParams = useGuideParams();
  const destination = `/${guideParams.app}`;

  return (
    <Link to={destination} className={c.backButton} tabIndex="-1">
      <Button size="small" variant="text" icon={<Icon.ChevronLeft />}>
        Back
      </Button>
    </Link>
  );
};

export default BackButton;
