import React from 'react';
import { Icon } from '@monash/portal-react';
import c from './text-link.module.scss';

const TextLink = ({
  text,
  cta,
  to,
  icon = 'External',
  iconPosition = 'right',
}) => {
  const iconName = { icon }.icon;
  const LinkIcon = Icon[iconName];

  const link = (
    <a className={c.link} href={to} target="_blank" rel="noreferrer">
      <div className={c[iconPosition]}>
        {cta}
        <LinkIcon color="var(--card-cta-bg-color)" />
        <span className={c.visuallyHidden}>. Opens in a new window</span>
      </div>
    </a>
  );

  return (
    <div className={c.text}>
      {text} {link}
    </div>
  );
};

export default TextLink;
