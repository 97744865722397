import React, { useState, useEffect } from 'react';
import { useGuideParams } from 'utils/hooks/useGuideParams';
import { transformText } from 'utils/fns/transformText';
import { shuffleArray } from 'utils/fns/shuffleArray';
import { getCategory } from 'data';
import GuideContainer from '../../../../ui/guide-container/GuideContainer';

const RelatedGuides = () => {
  // get page info
  const params = useGuideParams();
  const category = getCategory(params.app, params.category);
  const [guideList, setGuideList] = useState([]);

  useEffect(() => {
    const getGuides = () => {
      const guides = [...category.guides];
      const shuffledGuides = shuffleArray(guides)
        .filter((item) => transformText(item.name) !== params.guide)
        .slice(0, 3);

      // save into array
      return shuffledGuides.map((item) => item);
    };
    setGuideList(getGuides());
  }, [category.guides, params.guide]);

  return <GuideContainer title="Related guides" list={guideList} />;
};

export default RelatedGuides;
