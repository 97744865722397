import { transformText } from 'utils/fns/transformText';

export const userGuideData = [
  {
    app: 'Home',
    shortDescription:
      'Find out how to customise your Home app, use custom pages and widgets and view your schedule.',
    description:
      'Your home is the centre of the  Student Portal apps where you can access Monash digital products, personalise your home, add widgets and view your schedule for classes, exams and assessments, plus more.',
    categories: [
      {
        name: 'General',
        guides: [
          {
            name: 'Navigating the Home App',
            icon: 'Home',
            description: 'Learn to navigate and use the Home app.',
          },
          {
            name: 'Personalise your Home App',
            icon: 'User',
            description: 'Change your page order and theme in Home.',
          },
          {
            name: 'Upcoming Page',
            icon: 'Browser',
            description: 'View upcoming classes, assessments, and exams.',
          },
          {
            name: 'Change your Unit Colours',
            icon: 'Adjustments',
            description: 'Change the colour associated with your units.',
          },
          {
            name: 'Search',
            icon: 'Search',
            description: 'Search for resources from the Home app.',
          },
        ],
      },
      {
        name: 'Schedule Page',
        guides: [
          {
            name: 'Schedule page overview',
            icon: 'Calendar',
            description:
              'The Schedule page presents a weekly overview of your classes, exams, assessments and key dates.',
          },
          {
            name: 'Viewing your schedule',
            icon: 'LayoutCards',
            description: 'Explore viewing options for your schedule.',
          },
          {
            name: 'Navigating the Schedule page',
            icon: 'Data',
            description:
              'Navigate to future or past weeks to see your full schedule.',
          },
        ],
      },
      {
        name: 'Custom Page',
        guides: [
          {
            name: 'Custom pages',
            icon: 'Wand',
            description: 'Add custom pages and populate them with widgets.',
          },
          {
            name: 'Custom page widgets',
            icon: 'Note',
            description:
              'You can add, delete and move widgets on a custom page.',
          },
        ],
      },
    ],
  },
  {
    app: 'Profile',
    shortDescription:
      'Find more details and support services about profile and course progression.',
    description:
      'Your profile gives you access to Monash digital products and contains vital information related to your enrolment status, course progress, personal details, and more.',
    categories: [
      {
        name: 'Personal',
        guides: [
          {
            name: 'Change your avatar and pronouns',
            icon: 'User',
            description: 'Use an avatar and pronouns that best represent you.',
          },
        ],
      },
      {
        name: 'Course Progress',
        guides: [
          {
            name: 'View your course progression',
            icon: 'Data',
            description:
              'Take a look at your course progression, grades and credit points.',
          },
        ],
      },
    ],
  },
  {
    app: 'Links',
    shortDescription: 'Discover relevant Monash resources and information.',
    description:
      'Links allows you to discover Monash resources and information through either browsing or searching. It can help you find information on your studies, enrolments, fees, campus services and more.',
    categories: [
      {
        name: 'General',
        guides: [
          {
            name: 'Monash Links overview',
            icon: 'Link',
            description: 'Learn how to navigate and use Monash links.',
          },
        ],
      },
    ],
  },
];
export default userGuideData;

/**
 * getPaths
 * @param {array} data - array containing guide data objects
 * @returns {array} list of user guides paths
 */
export const getPaths = (data) => {
  return data
    .map((item) =>
      item.categories.map((e) =>
        e.guides.map((i) => transformText(`/${item.app}/${e.name}/${i.name}`))
      )
    )
    .flat(2);
};

/**
 * getApp
 * @param {string} app - app name in lowercase
 * @returns {string|object} target app object
 */
export const getApp = (app) => {
  const appTarget = userGuideData.find(
    (item) => item.app.toLowerCase() === app
  );

  if (appTarget) {
    return appTarget;
  } else {
    return 'App not found';
  }
};

/**
 * getCategory
 * @param {string} app - app name in lowercase
 * @param {string} category - app category name in kebab-case
 * @returns {string|object} target category object
 */
export const getCategory = (app, category) => {
  const catTarget = getApp(app).categories.find(
    (item) => transformText(item.name) === category
  );

  if (catTarget) {
    return catTarget;
  } else {
    return 'category not found';
  }
};

/**
 * getGuide
 * @param {string} guide - app guide names in kebab-case
 * @returns {string} raw target guide name
 */
export const getGuide = (guide) => {
  const allGuides = userGuideData
    .map((item) => item.categories.map((e) => e.guides.map((i) => i.name)))
    .flat(2);

  const guideTarget = allGuides.find((item) => transformText(item) === guide);

  if (guideTarget) {
    return guideTarget;
  } else {
    return 'Guide not found';
  }
};
