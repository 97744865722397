import React from 'react';
import Illustration from 'assets/illustrations/Illustration';
import c from './guide-header.module.scss';

const GuideHeader = ({ app, description }) => {
  const IllustrationForApp =
    typeof Illustration[app] === 'function' ? Illustration[app] : null;

  return (
    <div className={c.headerContainer}>
      <div className={c.illustration}>
        {IllustrationForApp !== null && <IllustrationForApp />}
      </div>
      <div className={c.header}>
        <h1>{app} guide</h1>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default GuideHeader;
