import React from 'react';
import c from './app-button.module.scss';
import { Link } from '@monash/portal-react';
import Illustration from 'assets/illustrations/Illustration';

const AppButton = ({ name, to, description }) => {
  const IllustrationForApp =
    typeof Illustration[name] === 'function' ? Illustration[name] : null;

  return (
    <Link to={to} className={c.appLink}>
      <div className={c.appCard}>
        <div className={c.illustrationBox}>
          <div className={c.illustration}>
            {IllustrationForApp !== null && <IllustrationForApp />}
          </div>
        </div>
        <div className={c.appButton}>
          <div className={c.title}>
            <h3>{name} guide</h3>
            <h3 className={c.visuallyHidden}>.</h3>
            <span>{description}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default AppButton;
