import SplunkOtelWeb from '@splunk/otel-web';
import SplunkSessionRecorder from '@splunk/otel-web-session-recorder';

const initMonitoring = (env) => {
  // SPLUNK
  if (env !== 'prod') {
    SplunkOtelWeb.init({
      realm: 'au0',
      rumAccessToken: '6eM1WLvdaCHtlkyqk5pqrQ',
      applicationName: 'user-guide',
      deploymentEnvironment: env,
    });

    SplunkSessionRecorder.init({
      realm: 'au0',
      rumAccessToken: '6eM1WLvdaCHtlkyqk5pqrQ',
    });
  }
};

export default initMonitoring;
