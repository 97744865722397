import React from 'react';
import c from './guide-category.module.scss';
import GuideContainer from 'components/ui/guide-container/GuideContainer';

const GuideCategory = ({ categories }) => {
  return (
    <section
      role="contentinfo"
      aria-label="Categories"
      className={c.guideCategories}
    >
      {categories && categories.length > 0 && (
        <ul>
          {categories.map((item) => (
            <li key={item.name}>
              <GuideContainer title={item.name} list={item.guides} />
            </li>
          ))}
        </ul>
      )}
      {(!categories || categories.length === 0) && <p>No categories found</p>}
    </section>
  );
};

export default GuideCategory;
