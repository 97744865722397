import React, { useRef } from 'react';
import c from './page-container.module.scss';
import { useScrollRestoration } from '@monash/portal-react';

const PageContainer = ({ children, type = 'default' }) => {
  const mainRef = useRef();
  useScrollRestoration({
    elemRef: mainRef,
    scrollConfig: { behavior: 'instant' },
  });

  return (
    <main
      className={[c.pageContainer, c[type]].join(' ')}
      id="mainContent"
      ref={mainRef}
    >
      <div className={c.contentContainer} id="contentContainer">
        {children}
      </div>
    </main>
  );
};

export default PageContainer;
