import React from 'react';
import c from './guide-container.module.scss';
import GuideCard from '../guide-card/GuideCard';

const GuideContainer = ({ title, list }) => {
  return (
    <section
      role="contentinfo"
      aria-label={`${title}`}
      className={c.guideContainer}
    >
      <h2>{title}</h2>
      <ul>
        {list.map((item, i) => (
          <li key={item.name + i}>
            <GuideCard
              name={item.name}
              icon={item.icon}
              description={item.description}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default GuideContainer;
