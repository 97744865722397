import React from 'react';
import c from './landing.module.scss';
import { portalLink } from '@monash/portal-frontend-common';
import PageContainer from '../../layouts/page-container/PageContainer';
import TextLink from '../../ui/text-link/TextLink';
import Apps from './apps/Apps';
import FeaturedGuides from '../../ui/featured-guides/FeaturedGuides';

const Landing = () => {
  return (
    <PageContainer>
      <div className={c.landing}>
        <h1 className={c.title}>Tips and Tricks for Monash Apps</h1>
        <Apps />
        <FeaturedGuides />
        <aside>
          <TextLink
            text="Have a question about your enrolment, units, or other student
          services?"
            cta="Search in Monash Links"
            to={portalLink('links')}
          />
        </aside>
      </div>
    </PageContainer>
  );
};

export default Landing;
