import React, { useEffect } from 'react';
import c from './guides-home.module.scss';
import GuideHeader from './guide-header/GuideHeader';
import GuideCategory from './guide-category/GuideCategory';
import usePageTitle from 'utils/hooks/usePageTitle';

const GuidesHome = ({ app, description, categories }) => {
  usePageTitle(`${app} guide | Monash University`);

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={c.guidesHome}>
      <GuideHeader app={app} description={description} />
      <GuideCategory categories={categories} />
    </div>
  );
};

export default GuidesHome;
