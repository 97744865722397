import React, { useState, useEffect } from 'react';
import c from './guide.module.scss';
import { useGuideParams } from '../../../../utils/hooks/useGuideParams';
import { getCategory } from 'data';
import { LoadingIndicator } from '@monash/portal-react';
import usePageTitle from 'utils/hooks/usePageTitle';
import RelatedGuides from './related-guides/RelatedGuides';
import GuideContent from './guide-content/GuideContent';
import FeaturedGuides from 'components/ui/featured-guides/FeaturedGuides';
import BackButton from './back-button/BackButton';

const Guide = ({ name }) => {
  const thisGuide = useGuideParams();
  const category = getCategory(thisGuide.app, thisGuide.category);
  usePageTitle(`${name} | Monash University`);

  // Loading & error states for Guide
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className={c.guide}>
      <BackButton />
      <div className={c.content}>
        {!showError && (
          <>
            <GuideContent onLoad={setLoading} onError={setShowError} />
            {loading && (
              <div className={c.loading}>
                <LoadingIndicator /> <p>Loading '{`${name}`}'</p>
              </div>
            )}
          </>
        )}
        {showError && <p>An error occurred.</p>}
      </div>

      <div className={c.divider} />

      {category.guides.length > 1 && <RelatedGuides />}
      {category.guides.length <= 1 && (
        <FeaturedGuides key={thisGuide.guide} exclude={thisGuide.guide} />
      )}
    </div>
  );
};

export default Guide;
