import React from 'react';
import c from './guide-card.module.scss';
import TextTruncate from 'react-text-truncate';
import { Icon, Link } from '@monash/portal-react';
import { transformText } from 'utils/fns/transformText';
import { userGuideData, getPaths } from '../../../data';

const GuideCard = ({ name, icon, description }) => {
  const guideName = transformText(name);
  const path = getPaths(userGuideData).find((allPaths) =>
    allPaths.includes(guideName)
  );

  const iconName = icon;
  const GuideIcon = Icon[iconName];

  return (
    <Link to={path} className={c.guideLink}>
      <div className={c.guideCard}>
        {/* If invalid icon, default to File */}
        {GuideIcon ? <GuideIcon /> : <Icon.File />}

        <h3>{name}</h3>
        {/* Pause between guide title and description */}
        <h3 className={c.visuallyHidden}>.</h3>
        <TextTruncate
          className={c.descriptionText}
          line={2}
          text={description}
        />
      </div>
    </Link>
  );
};

export default GuideCard;
