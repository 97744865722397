/**
 * getPublicImageURL
 * @param {object} guide - guide data object
 * @param {string} imageName - image name
 * @returns {string} remote guide image url string
 */
const getPublicImageURL = (guide, imageName) => {
  const baseUrl =
    window.location.hostname === 'localhost'
      ? 'https://user-guide.student-dev.monash'
      : window.location.origin;
  return `${baseUrl}/articles/${guide.app}/articles/${guide.category}/${guide.guide}/images/${imageName}`;
};

export default getPublicImageURL;
