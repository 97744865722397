import React, { useState, useContext } from 'react';
import c from './tab-nav.module.scss';
import { Tabs, RouterState } from '@monash/portal-react';
import { useGuideParams } from '../../../utils/hooks/useGuideParams';
import userGuideData, { getApp } from '../../../data.js';
import { transformText } from 'utils/fns/transformText';

const TabNav = () => {
  const tabs = userGuideData.map((item) => item.app);

  // default state should be the params of the url
  const guideParams = useGuideParams();

  // Get object info of the app
  const appInfo = getApp(guideParams.app);

  // Tabs
  const { redirect } = useContext(RouterState);
  const getTabsIndex = (tab) => tabs.indexOf(tab);
  const [appIndex, setAppIndex] = useState(getTabsIndex(appInfo.app));

  if (appIndex !== getTabsIndex(appInfo.app)) {
    setAppIndex(getTabsIndex(appInfo.app));
  }

  const changeTabs = (i) => {
    setAppIndex(i);
    redirect(`/${transformText(tabs[i])}`);
  };

  return (
    <div className={c.tabs} role="navigation" aria-label="App user guides">
      <Tabs
        aria-label="Tabs"
        tabs={tabs}
        selected={appIndex}
        onChange={(i) => {
          changeTabs(i);
        }}
      />
    </div>
  );
};

export default TabNav;
