import React from 'react';
import c from './preview-panel.module.scss';

const PreviewPanel = () => {
  return process.env.REACT_APP_SHOW_PREVIEW_PANEL ? (
    <div className={c.previewPanel}>This is a preview</div>
  ) : null;
};

export default PreviewPanel;
