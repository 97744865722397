import React, { useCallback, useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { useGuideParams } from 'utils/hooks/useGuideParams';
import getPublicGuideURL from 'utils/fns/getPublicGuideURL';
import getPublicImageURL from 'utils/fns/getPublicImageURL';

const GuideContent = ({ onLoad, onError }) => {
  const thisGuide = useGuideParams();
  const [guide, setGuide] = useState(null);

  const replaceFileNamesbyURLs = useCallback(
    async (content) => {
      const rx = /(?<=!\[.*]\().*(?=\))/gi;
      const fileNames = [...new Set(content.match(rx))];
      const promises = fileNames.map((item) =>
        getPublicImageURL(thisGuide, item)
      );

      if (promises) {
        await Promise.allSettled(promises).then((results) =>
          results.forEach((item, i) => {
            // Prevent double replacement issue
            content = content.replaceAll(
              '(' + fileNames[i] + ')',
              '(' + item.value + ')'
            );
          })
        );
      }

      return content;
    },
    [thisGuide]
  );

  const getGuideFile = useCallback(() => {
    if (!thisGuide.app || !thisGuide.category || !thisGuide.guide) return;
    onLoad(true);
    // TODO: add relative path to article documents
    fetch(getPublicGuideURL(thisGuide))
      .then((res) => res.text())
      .then((text) => replaceFileNamesbyURLs(text))
      .then((raw) => {
        setGuide(raw);
      })
      .then(() => {
        onLoad(false);
      })
      .catch((err) => {
        // TODO: update error handling method for different statuses
        console.error(err);
        onError(true);
      });
  }, [thisGuide, onLoad, onError, replaceFileNamesbyURLs]);

  useEffect(() => {
    getGuideFile();
  }, [getGuideFile]);

  return <ReactMarkdown rehypePlugins={[rehypeRaw]}>{guide}</ReactMarkdown>;
};

export default GuideContent;
