import { useContext, useMemo } from 'react';
import { RouterState } from '@monash/portal-react';

export const useGuideParams = () => {
  const { route } = useContext(RouterState);

  const app = route.path.split('/')[1];
  const category = route.path.split('/')[2];
  const guide = route.path.split('/')[3];

  return useMemo(
    () => ({
      app,
      category,
      guide,
    }),
    [app, category, guide]
  );
};
