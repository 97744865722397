import React, { useState, useEffect } from 'react';
import GuideContainer from 'components/ui/guide-container/GuideContainer';
import { shuffleArray } from 'utils/fns/shuffleArray';
import { transformText } from 'utils/fns/transformText';
import userGuideData from 'data';

const FeaturedGuides = ({ exclude }) => {
  const [featuredGuides, setFeaturedGuides] = useState([]);

  useEffect(() => {
    const allGuides = userGuideData
      .map((item) => item.categories.map((e) => e.guides.map((i) => i)))
      .flat(2);

    const generateFeaturedGuides = () => {
      // get copy of all guides
      const guides = [...allGuides];

      if (!exclude) {
        // randomly generate 3 guides
        const featuredGuides = shuffleArray(guides).slice(0, 3);
        setFeaturedGuides(featuredGuides);
      } else {
        // remove the excluded guide from the list, shuffle, and take 3
        const featuredGuides = shuffleArray(guides)
          .filter((item) => transformText(item.name) !== exclude)
          .slice(0, 3);
        setFeaturedGuides(featuredGuides);
      }
    };

    generateFeaturedGuides();
  }, [exclude]);

  return <GuideContainer title="Featured guides" list={featuredGuides} />;
};

export default FeaturedGuides;
