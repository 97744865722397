/**
 * getPublicGuideURL
 * @param {object} guide - guide data object
 * @returns {string} remote guide url string
 */
const getPublicGuideURL = (guide) => {
  const baseUrl =
    window.location.hostname === 'localhost'
      ? 'https://user-guide.student-dev.monash'
      : window.location.origin;
  return `${baseUrl}/articles/${guide.app}/articles/${guide.category}/${guide.guide}/${guide.guide}.md`;
};

export default getPublicGuideURL;
