import React from 'react';
import c from './user-guide.module.scss';
import { portalLink } from '@monash/portal-frontend-common';
import { useGuideParams } from '../../../utils/hooks/useGuideParams';
import TextLink from '../../ui/text-link/TextLink';
import GuidesHome from './guides-home/GuidesHome';
import Guide from './guide/Guide';
import { getApp, getGuide } from '../../../data.js';

const UserGuide = () => {
  // default state should be the params of the url
  const guideParams = useGuideParams();

  // Get object info of the app
  const appInfo = getApp(guideParams.app);

  return (
    <div className={c.userGuideContainer}>
      {/* If guide is NOT selected, show guide home */}
      {!guideParams.guide && (
        <GuidesHome
          app={appInfo.app}
          description={appInfo.description}
          categories={appInfo.categories}
        />
      )}
      {/* If guide is selected, show guides */}
      {guideParams.guide && <Guide name={getGuide(guideParams.guide)} />}

      <aside className={c.extLink}>
        <TextLink
          text="Have a question about your enrolment, units, or other student
          services?"
          cta="Search in Monash Links"
          to={portalLink('links')}
        />
      </aside>
    </div>
  );
};

export default UserGuide;
