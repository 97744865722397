import React from 'react';
import AppButton from './app-button/AppButton';
import c from './apps.module.scss';
import { transformText } from 'utils/fns/transformText';
import userGuideData from 'data';

const Apps = () => {
  const appList = userGuideData.map((item) => ({
    name: `${item.app}`,
    to: `/${transformText(item.app)}`,
    description: `${item.shortDescription}`,
  }));

  return (
    <section role="contentinfo" aria-label="App guides" className={c.apps}>
      <h2>App guides</h2>
      <ul>
        {appList.map((item, i) => (
          <li key={item.name + i}>
            <AppButton
              name={item.name}
              to={item.to}
              description={item.description}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Apps;
